<template>
  <div class="form-group"
    v-bind:class="[
      classes,
      `col-${config.cols || 12}`
    ]">
    <label v-bind:for="config.id" v-if="showLabel">{{config.label}}</label>

    <template v-if="['text', 'number', 'email', 'tel'].includes(config.type)">
      <input
        class="form-control"
        v-bind:name="config.id"
        v-bind:class="[{ 'is-invalid': validation.$error, }, inputClasses]"
        v-bind:id="config.id"
        v-bind:type="config.type"
        v-bind:placeholder="config.placeholder"
        v-bind:min="config.min"
        v-bind:max="config.max"
        v-model.trim="validation.$model"/>
    </template>

    <template v-if="config.type == 'textarea'">
      <textarea
        class="form-control"
        v-bind:name="config.id"
        v-bind:class="[{ 'is-invalid': validation.$error, }, inputClasses]"
        v-bind:id="config.id"
        v-bind:type="config.type"
        v-bind:placeholder="config.placeholder"
        v-bind:min="config.min"
        v-bind:max="config.max"
        v-bind:rows="config.rows"
        v-model.trim="validation.$model"></textarea>
    </template>
    

    <template v-if="config.type == 'select'">
      <select class="form-control"
        v-bind:name="config.id"
        v-bind:class="[{ 'is-invalid': validation.$error, }, inputClasses]"
        v-bind:id="config.id"
        v-bind:type="config.type"
        v-bind:placeholder="config.placeholder"
        v-model.trim="validation.$model">
        <option v-bind:value="null">Seleccione una opción</option>
        <option v-for="option in config.options"
          v-bind:key="option.value"
          v-bind:value="option.value">
          {{option.text}}
        </option>
      </select>
    </template>

    <div class="invalid-feedback"
      v-bind:class="{ 'd-block': validation.$error }">
      <span>Proporcione un valor válido</span>
    </div>  
  </div>
</template>

<script>
  export default {
    props: {
      config: Object,
      validation: Object,
      classes: String,
      inputClasses: String,
      showLabel: Boolean,
    },
  };
</script>